import React, { useEffect, useState, ReactNode, RefObject } from 'react';
import Slider, { Settings } from 'react-slick';

/**
 * With SSR, `react-slick` is mixing up rendered images when using responsive breakpoints.
 * Two-pass rendering trick can be used to get around it. This whole component is a wrapper to do so.
 * More in-depth explanation at: https://github.com/akiran/react-slick/issues/1245#issuecomment-557909054
 */

const Carousel: React.FC<
  { children: ReactNode[] | null; forwardRef?: RefObject<Slider> } & Settings
> = ({ children, forwardRef, ...props }) => {
  const [isClient, setIsClient] = useState<boolean>(false);
  useEffect(() => setIsClient(true), []);

  return (
    <Slider key={isClient ? 'client' : 'server'} ref={forwardRef} {...props}>
      {children}
    </Slider>
  );
};

export default Carousel;
export type CarouselSettings = Settings;
export { default as SlickSlider } from 'react-slick';
